import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useState,
} from "react";
import { functions, database } from "../../shared/config/firebase";
import { httpsCallable } from "firebase/functions";
import { ref, get } from "firebase/database";

type Organization = {
  id: string;
  name: string;
  logo: string;
  description: string;
};

type NewOrganization = {
  name: string;
  logo: string;
  description: string;
};

type Location = {
  name: string;
  uid: string;
};

type OrganizationContextType = {
  selectedOrganization: string;
  organizations: Organization[];
  orgLocations: Location[];
  currentOrganization: Organization | null;
  getOrganizations: () => Promise<void>;
  getOrganizationById: (organizationUid: string) => Promise<void>;
  getLocationsByOrganizationId: (organizationUid: string) => Promise<void>;
  createOrganization: (newOrg: NewOrganization) => Promise<void>;
  updateOrganization: (id: string, updatedOrg: NewOrganization) => Promise<void>;
  deleteOrganization: (id: string) => Promise<void>;
  createLocation: (organizationUid: string, locationName: string) => Promise<void>;
  deleteLocation: (locationUid: string, organizationUid: string) => Promise<void>;
};

type OrganizationProviderProps = {
  children: ReactNode;
};

export const OrganizationContext = createContext<OrganizationContextType | null>(null);

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error("useOrganization must be used within an OrganizationProvider");
  }
  return context;
};

export const OrganizationProvider: FC<OrganizationProviderProps> = ({ children }) => {
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
  const [orgLocations, setOrgLocations] = useState<Location[]>([]);

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    const getOrganizationsFunc = httpsCallable<{}, Organization[]>(functions, "getUserOrganizations");
    try {
      const response = await getOrganizationsFunc();
      setOrganizations(response.data);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const getOrganizationById = async (organizationUid: string) => { //TODO make cloud function
    const orgRef = ref(database, `organizations/info/${organizationUid}`);
    try {
      const snapshot = await get(orgRef);
      if (snapshot.exists()) {
        setCurrentOrganization(snapshot.val());
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error getting organization:", error);
    }
  };

  const getLocationsByOrganizationId = async (organizationUid: string) => {
    const getLocationsFunc = httpsCallable<{ organizationUid: string }, Location[]>(functions, "getUserLocationsByOrganizationUid");
    try {
      resetState();
      const response = await getLocationsFunc({ organizationUid });
      //sort by locations name. 1-n, a-z
      const sortedLocations = response.data.sort((a, b) => {
  const numA = parseInt(a.name.split(' ')[0], 10);
  const numB = parseInt(b.name.split(' ')[0], 10);

  if (isNaN(numA) && isNaN(numB)) {
    return a.name.localeCompare(b.name); // Both non-numeric, compare as strings
  } else if (isNaN(numA)) {
    return 1; // Non-numeric entries come after numeric ones
  } else if (isNaN(numB)) {
    return -1; // Non-numeric entries come after numeric ones
  }
  
  return numA - numB; // Compare numeric values
});


      setOrgLocations(sortedLocations);
    } catch (error) {
      console.error("Error getting locations:", error);
    }
  };

  const createOrganization = async (newOrg: NewOrganization) => {
    const createOrgFunc = httpsCallable<NewOrganization, Organization>(functions, "createOrganization");
    try {
      await createOrgFunc(newOrg);
      getOrganizations();
    } catch (error) {
      console.error("Error creating organization:", error);
    }
  };

  const updateOrganization = async (id: string, updatedOrg: NewOrganization) => {
    const updateOrgFunc = httpsCallable<{ organizationUid: string; name?: string, logo?:string, description?: string}, Organization>(functions, "updateOrganization");
    try {
      await updateOrgFunc({ organizationUid: id, ...updatedOrg });
      getOrganizations();
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };

  const deleteOrganization = async (id: string) => {
    const deleteOrgFunc = httpsCallable<{ organizationUid: string }, void>(functions, "deleteOrganization");
    try {
      await deleteOrgFunc({ organizationUid: id });
      getOrganizations();
    } catch (error) {
      console.error("Error deleting organization:", error);
    }
  };

  const createLocation = async (organizationUid: string, locationName: string) => {
    const createLocationFunc = httpsCallable<{ organizationUid: string; name: string }, Location>(functions, "createLocation");
    try {
      const response = await createLocationFunc({ organizationUid, name: locationName });
      getLocationsByOrganizationId(organizationUid);
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  const deleteLocation = async (locationUid: string, organizationUid: string) => {
    const deleteLocationFunc = httpsCallable<{ locationUid: string, organizationUid: string }, void>(functions, "deleteLocation");
    try {
      await deleteLocationFunc({ locationUid: locationUid, organizationUid: organizationUid });
      getLocationsByOrganizationId(organizationUid);
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  const resetState = () => {
    setSelectedOrganization("");
    setOrganizations([]);
    setCurrentOrganization(null);
    setOrgLocations([]);
  }

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization,
        organizations,
        orgLocations,
        currentOrganization,
        getOrganizations,
        getOrganizationById,
        getLocationsByOrganizationId,
        createOrganization,
        updateOrganization,
        deleteOrganization,
        createLocation,
        deleteLocation,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

